
import { defineComponent, ref, computed, onMounted} from "vue";
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    isNew: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "Form",
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const loading = ref(false)
    const form = ref(props.data);
    const getRoles = () => {
      store.dispatch("Option/getAllRoles");
    };

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const setLoading = (payload: boolean) => {
      loading.value = payload
    }

    const roles = computed(() => store.getters["Option/all_roles"]);

    onMounted(getRoles);
    return {
      form,
      roles,
      loading,
      filterOption,
      setLoading
    };
  },
});
