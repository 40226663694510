
import Form from './Form.vue'
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs, reactive } from 'vue'

export default defineComponent({
  components: {
    Form
  },
  setup () {
    const form = reactive({
      id: '',
      user_name: '',
      email: '',
      password: '',
      role_id: '',
      salutation: '',
      first_name: '',
      middle_name: '',
      last_name: ''
    })
    const form_action = ref('save')
    const visible = ref(false)
    const isNew = ref(false)
    const store = useStore()
    const modal_form = ref<InstanceType<typeof Form>>()

    const fields = ref([
      {
        title: 'Full Name',
        dataIndex: 'full_name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Role Name',
        dataIndex: 'role.name',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('User/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, user_name, email, role_id, password, salutation, first_name, middle_name, last_name} = toRefs(form)
      store
          .dispatch('User/show', record.id)
          .then(
              (res) => (
                  id.value = res.data.id,
                      user_name.value = res.data.user_name,
                      email.value = res.data.email,
                      role_id.value = res.data.role.id,
                      salutation.value = res.data.salutation,
                      first_name.value = res.data.first_name,
                      last_name.value = res.data.last_name,
                      middle_name.value = res.data.middle_name
              )
          )

      visible.value = true
      isNew.value = false
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {id, user_name, role_id, email, password, salutation, first_name, middle_name, last_name} = toRefs(form)
      id.value = ''
      user_name.value = ''
      email.value = ''
      password.value = ''
      role_id.value = ''
      salutation.value = ''
      first_name.value = ''
      middle_name.value = ''
      last_name.value = ''
      visible.value = true
      isNew.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('User/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record)
    }

    const storeRecord = (payload: any) => {
      modal_form.value?.setLoading(true)
      store
          .dispatch('User/store', payload)
          .then(() => (visible.value = false))
          .finally(() => modal_form.value?.setLoading(false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('User/update', payload)
          .then(() => (visible.value = false))
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['User/items'])
    const loading = computed(() => store.getters['User/status'])

    onMounted(index)

    return {
      loading,
      items,
      fields,
      visible,
      form,
      isNew,
      modal_form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    }
  },
})
